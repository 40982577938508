import React from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";

const SearchBox = ({
  buildInputName,
  updateFormValue,
  updateFormValues,
  meddraSearch,
  searchLocked,
  meddraError,
  readonly,
  setResponseData,
  rawTermFieldData,
  lltTermFieldData,
  loading,
  useAdvancedMeddra,
}) => {
  const inputName = buildInputName("raw_term");

  const onChangeHandler = (e) => {
    updateFormValue("raw_term", e.target.value);
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      meddraSearch();
    }
  };

  let formControlClasses = ["px-2", "py-1"];
  if ((searchLocked && !readonly) || loading) {
    formControlClasses.push("field-bg-readonly noClick");
  }

  const buildSearchButton = () => {
    if (readonly || useAdvancedMeddra) {
      return null;
    }

    const unlockSearch = () => {
      setResponseData(null);
      // Ensure that we reset our form values when the search is unlocked
      updateFormValues([
        ["llt_term", null],
        ["llt_code", null],
        ["pt_term", null],
        ["pt_code", null],
        ["soc_term", null],
        ["soc_code", null],
        ["alternate_search_keyword", null],
        ["api_response", null],
        ["meddra_version", null],
      ]);
    };

    if (searchLocked) {
      return (
        <div
          className="btn btn-primary"
          onClick={unlockSearch}
          id="meddra-unlock"
        >
          Unlock Search Term
        </div>
      );
    } else if (loading) {
      return (
        <div className="btn btn-primary disabled" disabled={true}>
          Loading <i className="fas fa-spinner-third fa-spin"></i>
        </div>
      );
    } else {
      let buttonClasses = ["btn", "btn-primary"];
      if (!rawTermFieldData.value) {
        buttonClasses.push("disabled");
      }

      return (
        <div
          className={buttonClasses.join(" ")}
          disabled={!rawTermFieldData.value}
          onClick={meddraSearch}
          id="meddra-search"
        >
          {lltTermFieldData.value ? "Search to Edit Coding" : "Search"}
        </div>
      );
    }
  };

  return (
    <>
      {meddraError && (
        <Alert variant="danger" className="mt-2 col-8">
          {meddraError}
        </Alert>
      )}
      <div className="mb-3 mt-3 align-items-center row">
        <Form.Label className="fw-bold lh-sm form-label col-form-label col-sm-5">
          Adverse Event Term
        </Form.Label>
        <div className="col-sm-auto">
          <InputGroup>
            {searchLocked && !readonly && (
              <InputGroup.Text>
                <i className="fas fa-lock"></i>
              </InputGroup.Text>
            )}
            <Form.Control
              plaintext={readonly}
              readOnly={searchLocked || loading}
              type="text"
              value={rawTermFieldData.value || ""}
              name={inputName}
              onChange={onChangeHandler}
              onKeyDown={keyDownHandler}
              className={formControlClasses.join(" ")}
            ></Form.Control>
            {buildSearchButton()}
          </InputGroup>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
