import React from "react";

import { Table, Form } from "react-bootstrap";

const ResultTable = ({ forms, selectForm, selectedForms }) => {
  const renderedRows = forms.map((form, index) => {
    const identifier = `${form.class}-${form.id}`;
    const checked = selectedForms.includes(identifier);
    return (
      <tr
        key={form.participant_id}
        id={form.participant_id.replace(/\//g, "-")}
      >
        <td>
          <a href={form.individual_link}>{form.participant_id}</a>
        </td>
        <td>
          {form.start_date || <span className="text-muted">[blank]</span>}
        </td>
        <td>{form.details || <span className="text-muted">[blank]</span>}</td>
        <td>
          <a href={form.form_link}>{form.raw_term}</a>
        </td>
        <td>
          <Form.Check
            type="checkbox"
            name="approve_form"
            checked={checked}
            onChange={(e) => {
              selectForm(identifier, e.target.checked);
            }}
          ></Form.Check>
        </td>
      </tr>
    );
  });

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th className="">Participant ID</th>
          <th className="">Start Date</th>
          <th className="">Details</th>
          <th className="">Raw Term</th>
          <th className="">Mark for Approval</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
