import React from "react";
import { useState } from "react";

import cloneDeep from "lodash/cloneDeep";

import ResultTable from "../components/keyword_coded_meddra_report/resultsTable";
import ReportFilter from "../components/keyword_coded_meddra_report/ReportFilter";

const KeywordCodedMeddraReportPage = ({ studyId, initialData }) => {
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedCodingStatus, setSelectedCodingStatus] = useState();

  const updateSiteSelect = (a, b) => {
    setSelectedSites(b);
  };

  const updateCodingStatus = (a, b) => {
    setSelectedCodingStatus(b);
  };

  const buildFilteredForms = () => {
    let filteredForms = cloneDeep(initialData);

    if (selectedSites.length > 0) {
      filteredForms = filteredForms.filter((form) => {
        return selectedSites.includes(form.site);
      });
    }

    if (selectedCodingStatus != null) {
      filteredForms = filteredForms.filter((form) => {
        return form.unable_to_code != selectedCodingStatus;
      });
    }

    return filteredForms;
  };

  const buildCSVData = () => {
    return filteredForms.map((form) => {
      return [
        form.participant_id,
        form.start_date,
        form.raw_term,
        form.alternate_search_keywords,
        form.unable_to_code ? "Yes" : "No",
        form.llt_term,
        form.pt_term,
      ];
    });
  };

  const buildSites = () => {
    let sites = {};

    initialData.forEach((form) => {
      sites[form.site] = { value: form.site, label: form.site };
    });

    return Object.keys(sites).map(function (site) {
      return sites[site];
    });
  };

  const sites = buildSites();

  const filteredForms = buildFilteredForms();
  const csvData = buildCSVData();

  const filterProps = {
    studyId,
    sites,
    selectedSites,
    updateSiteSelect,
    selectedCodingStatus,
    updateCodingStatus,
    csvData,
  };

  return (
    <>
      <ReportFilter {...filterProps}></ReportFilter>
      {filteredForms.length == 0 ? (
        <div className="text-muted">
          There are no forms which match your current filters.
        </div>
      ) : (
        <ResultTable forms={filteredForms}></ResultTable>
      )}
    </>
  );
};

export default KeywordCodedMeddraReportPage;
