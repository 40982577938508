import React from "react";

import { Table, Form } from "react-bootstrap";

const ResultTable = ({
  forms,
  approvedCombinations,
  inappropriateCombinations,
  selectApprovedForm,
  selectInappropriateForm,
}) => {
  const renderedRows = forms.map((form, i) => {
    const approvedChecked = approvedCombinations.includes(form);
    const inappropriateChecked = inappropriateCombinations.includes(form);

    if (form.similar_codings.length == 0) {
      return (
        <tr key={`row-${i}`} id={`row-${i}`}>
          <td>{form.raw_term}</td>
          <td>{form.llt_term}</td>
          <td>{form.pt_term}</td>
          <td>{form.soc_term}</td>
          <td>
            {form.screenings.map((screening) => {
              return (
                <div key={screening.id}>
                  <a href={screening.link}>{screening.id}</a>
                </div>
              );
            })}
          </td>
          <td className="text-muted" colSpan={3}>
            [No similar approved codings]
          </td>
          <td className="text-center">
            <Form.Check
              type="checkbox"
              name="mark_inappropriate"
              checked={inappropriateChecked}
              onChange={(e) => {
                selectInappropriateForm(form, e.target.checked);
              }}
            ></Form.Check>
          </td>
          <td className="text-center">
            <Form.Check
              type="checkbox"
              name="mark_approved"
              checked={approvedChecked}
              onChange={(e) => {
                selectApprovedForm(form, e.target.checked);
              }}
            ></Form.Check>
          </td>
        </tr>
      );
    } else {
      return form.similar_codings.map((similar_coding, ii) => {
        if (ii == 0) {
          return (
            <tr key={`row-${i}-${ii}`} id={`row-${i}-${ii}`}>
              <td rowSpan={form.similar_codings.length}>{form.raw_term}</td>
              <td rowSpan={form.similar_codings.length}>{form.llt_term}</td>
              <td rowSpan={form.similar_codings.length}>{form.pt_term}</td>
              <td rowSpan={form.similar_codings.length}>{form.soc_term}</td>
              <td rowSpan={form.similar_codings.length}>
                {form.screenings.map((screening) => {
                  return (
                    <div key={screening.id}>
                      <a href={screening.link}>{screening.id}</a>
                    </div>
                  );
                })}
              </td>
              <td className="text-muted" id={`row-${i}-${ii}-raw-term`}>
                {similar_coding.raw_term}
              </td>
              <td className="text-muted" id={`row-${i}-${ii}-llt-term`}>
                {similar_coding.llt_term}
              </td>
              <td className="text-muted" id={`row-${i}-${ii}-soc-term`}>
                {similar_coding.soc_term}
              </td>
              <td rowSpan={form.similar_codings.length} className="text-center">
                <Form.Check
                  type="checkbox"
                  name="mark_inappropriate"
                  checked={inappropriateChecked}
                  onChange={(e) => {
                    selectInappropriateForm(form, e.target.checked);
                  }}
                ></Form.Check>
              </td>
              <td rowSpan={form.similar_codings.length} className="text-center">
                <Form.Check
                  type="checkbox"
                  name="mark_approved"
                  checked={approvedChecked}
                  onChange={(e) => {
                    selectApprovedForm(form, e.target.checked);
                  }}
                ></Form.Check>
              </td>
            </tr>
          );
        } else {
          return (
            <tr
              key={`row-${i}-${ii}`}
              id={`row-${i}-${ii}`}
              className="text-muted"
            >
              <td>{similar_coding.raw_term}</td>
              <td>{similar_coding.llt_term}</td>
              <td>{similar_coding.soc_term}</td>
            </tr>
          );
        }
      });
    }
  });

  return (
    <Table bordered>
      <thead className="thead-light">
        <tr>
          <th rowSpan={2} className="">
            AE Raw Term
          </th>
          <th rowSpan={2} className="">
            LLT
          </th>
          <th rowSpan={2} className="">
            PT
          </th>
          <th rowSpan={2} className="">
            SOC
          </th>
          <th rowSpan={2}>Coding Used By</th>
          <th className="text-center text-muted" colSpan={3}>
            Similar Approved Codings
          </th>
          <th rowSpan={2} className="">
            Inappropriate
          </th>
          <th rowSpan={2} className="">
            Approve
          </th>
        </tr>
        <tr className="text-muted">
          <th>Raw Term</th>
          <th>LLT</th>
          <th>SOC</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
