import React from "react";

import { Table } from "react-bootstrap";

const ResultTable = ({ forms }) => {
  const renderedRows = forms.map((form, index) => {
    return (
      <tr
        key={form.participant_id}
        id={form.participant_id.replace(/\//g, "-")}
      >
        <td>
          <a href={form.individual_link}>{form.participant_id}</a>
        </td>
        <td>
          {form.start_date || <span className="text-muted">[blank]</span>}
        </td>
        <td>
          <a href={form.form_link}>{form.raw_term}</a>
        </td>
        <td>{form.alternate_search_keywords ? "Yes" : "No"}</td>
      </tr>
    );
  });

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th className="">Participant ID</th>
          <th className="">Start Date</th>
          <th className="">Raw Term</th>
          <th className="">Keyword Coded?</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
