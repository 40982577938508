import React, { Fragment } from "react";
import { useState } from "react";

import cloneDeep from "lodash/cloneDeep";

import ResultTable from "../components/meddra_approval_report/resultsTable";
import ReportFilter from "../components/meddra_approval_report/ReportFilter";
import { CSVDownloadButton } from "components/helpers";
import { Button } from "react-bootstrap";
import { getCsrfToken } from "../services/csrf_helper";

const MeddraApprovalsReportPage = ({ studyId, data }) => {
  const [selectedExactMatch, setSelectedExactMatch] = useState(false);

  const updateSelectedExactMatch = (a, b) => {
    setSelectedExactMatch(b);
  };

  const buildFilteredForms = () => {
    let filteredForms = data;

    filteredForms = filteredForms.filter((form) => {
      return !form.approval_status;
    });

    if (selectedExactMatch) {
      filteredForms = filteredForms.filter((form) => {
        return form.exact_match === true;
      });
    }

    return filteredForms;
  };

  const buildCSVData = () => {
    let filteredForms = data;

    if (selectedExactMatch) {
      filteredForms = filteredForms.filter((form) => {
        return form.exact_match === true;
      });
    }

    return filteredForms.map((form) => {
      return [
        form.raw_term,
        form.llt_term,
        form.pt_term,
        form.soc_term,
        form.approval_status,
        form.created_by,
        form.created_at,
      ];
    });
  };

  const filteredForms = buildFilteredForms();
  const csvData = buildCSVData();

  const [approvedCombinations, setApprovedCombinations] = useState([]);
  const [inappropriateCombinations, setInappropriateCombinations] = useState(
    []
  );

  const selectApprovedForm = (form, value) => {
    selectForm(form, value, approvedCombinations, setApprovedCombinations);
    if (value) {
      selectForm(
        form,
        false,
        inappropriateCombinations,
        setInappropriateCombinations
      );
    }
  };

  const selectInappropriateForm = (form, value) => {
    selectForm(
      form,
      value,
      inappropriateCombinations,
      setInappropriateCombinations
    );
    if (value) {
      selectForm(form, false, approvedCombinations, setApprovedCombinations);
    }
  };

  const selectForm = (form, value, state, setter) => {
    const formInArray = state.includes(form);

    if (formInArray && value == false) {
      const newSelectedForms = state.filter((item) => {
        return item != form;
      });
      setter(newSelectedForms);
    }

    if (!formInArray && value == true) {
      const newSelectedForms = [...state, form];
      setter(newSelectedForms);
    }
  };

  const csrfToken = getCsrfToken();

  const buildForm = () => {
    const postLocation = `/studies/${studyId}/reports/meddra_approvals/submit_terms`;

    const buildApprovedInputs = () => {
      return approvedCombinations.map((form, index) => {
        return (
          <Fragment key={`approved-${index}`}>
            <input
              type="hidden"
              name="approved_forms[]raw_term"
              value={form.raw_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]llt_code"
              value={form.llt_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]llt_term"
              value={form.llt_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]pt_code"
              value={form.pt_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]pt_term"
              value={form.pt_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]soc_code"
              value={form.soc_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]soc_term"
              value={form.soc_term}
            ></input>
          </Fragment>
        );
      });
    };

    const buildInappropriateInputs = () => {
      return inappropriateCombinations.map((form, index) => {
        return (
          <Fragment key={`inappropriate-${index}`}>
            <input
              type="hidden"
              name="inappropriate_forms[]raw_term"
              value={form.raw_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]llt_code"
              value={form.llt_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]llt_term"
              value={form.llt_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]pt_code"
              value={form.pt_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]pt_term"
              value={form.pt_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]soc_code"
              value={form.soc_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]soc_term"
              value={form.soc_term}
            ></input>
          </Fragment>
        );
      });
    };

    return (
      <form
        action={postLocation}
        method="post"
        className="mb-2 d-flex justify-content-end"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken || ""}
        />
        {buildApprovedInputs()}
        {buildInappropriateInputs()}
        <Button
          variant="primary"
          type="submit"
          disabled={
            approvedCombinations.length == 0 &&
            inappropriateCombinations.length == 0
          }
          data-confirm="This cannot be undone, are you sure you would like to submit the selected forms"
        >
          Submit Selected Forms
        </Button>
      </form>
    );
  };

  const filterProps = {
    studyId,
    selectedExactMatch,
    updateSelectedExactMatch,
    csvData,
  };

  return (
    <>
      {data.length > 0 && <ReportFilter {...filterProps}></ReportFilter>}
      {filteredForms.length == 0 ? (
        <div className="text-muted">
          There are no available combinations to review.
        </div>
      ) : (
        <>
          {buildForm()}
          <ResultTable
            forms={filteredForms}
            approvedCombinations={approvedCombinations}
            inappropriateCombinations={inappropriateCombinations}
            selectApprovedForm={selectApprovedForm}
            selectInappropriateForm={selectInappropriateForm}
          ></ResultTable>
        </>
      )}
    </>
  );
};

export default MeddraApprovalsReportPage;
