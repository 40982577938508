import React from "react";
import { useState } from "react";

import cloneDeep from "lodash/cloneDeep";

import ResultTable from "../components/meddra_version_update_report/resultsTable";
import ReportFilter from "../components/meddra_version_update_report/ReportFilter";
import { CSVDownloadButton } from "components/helpers";

const MeddraVersionUpdateReportPage = ({ studyId, meddraVersion, data }) => {
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedMismatchStatus, setSelectedMismatchStatus] = useState();

  const updateSiteSelect = (a, b) => {
    setSelectedSites(b);
  };

  const updateMismatchStatus = (a, b) => {
    setSelectedMismatchStatus(b);
  };

  const buildFilteredForms = () => {
    let filteredForms = cloneDeep(data);

    if (selectedSites.length > 0) {
      filteredForms = filteredForms.filter((form) => {
        return selectedSites.includes(form.site);
      });
    }

    if (selectedMismatchStatus != null) {
      filteredForms = filteredForms.filter((form) => {
        return (form.meddra_version == meddraVersion) != selectedMismatchStatus;
      });
    }

    return filteredForms;
  };

  const buildCSVData = () => {
    return filteredForms.map((form) => {
      return [
        form.participant_id,
        form.start_date,
        form.raw_term,
        form.alternate_search_keywords || "[none]",
        form.llt_term,
        form.pt_term,
        form.meddra_version,
        form.meddra_version != meddraVersion ? "Yes" : "No",
        form.meddra_update_state,
      ];
    });
  };

  const buildSites = () => {
    let sites = {};

    data.forEach((form) => {
      sites[form.site] = { value: form.site, label: form.site };
    });

    return Object.keys(sites).map(function (site) {
      return sites[site];
    });
  };

  const sites = buildSites();

  const filteredForms = buildFilteredForms();
  const csvData = buildCSVData();

  const filterProps = {
    studyId,
    sites,
    selectedSites,
    updateSiteSelect,
    selectedMismatchStatus,
    updateMismatchStatus,
    csvData,
  };

  return (
    <>
      {data.length == 0 ? (
        <div className="text-muted">There are no MedDRA forms.</div>
      ) : (
        <>
          <ReportFilter {...filterProps}></ReportFilter>
          <div className="mb-2 text-muted">
            This study's current MedDRA version is: {meddraVersion}
          </div>
          {filteredForms.length == 0 ? (
            <div className="text-muted">
              There are no forms which match your current filters.
            </div>
          ) : (
            <ResultTable
              forms={filteredForms}
              currentMeddraVersion={meddraVersion}
            ></ResultTable>
          )}
        </>
      )}
    </>
  );
};

export default MeddraVersionUpdateReportPage;
