import React from "react";

import { useState } from "react";

import ResultTable from "../components/ae_term_reconciliation_report/resultsTable";
import { CSVDownloadButton } from "components/helpers";
import { Button } from "react-bootstrap";
import { getCsrfToken } from "../services/csrf_helper";

const AeTermReconciliationReportPage = ({ studyId, data }) => {
  const [selectedForms, setSelectedForms] = useState([]);

  const filteredData = data.filter((datum) => {
    return !datum.raw_term_approved;
  });

  const buildCSVData = () => {
    return data.map((form) => {
      return [
        form.participant_id,
        form.start_date,
        form.details,
        form.raw_term,
        form.raw_term_approved ? "Y" : "N",
      ];
    });
  };

  const csvData = buildCSVData();

  const selectForm = (identifier, value) => {
    const identifierInArray = selectedForms.includes(identifier);

    if (identifierInArray && value == false) {
      const newSelectedForms = selectedForms.filter((item) => {
        return item != identifier;
      });
      setSelectedForms(newSelectedForms);
    }

    if (!identifierInArray && value == true) {
      const newSelectedForms = [...selectedForms, identifier];
      setSelectedForms(newSelectedForms);
    }
  };

  const csrfToken = getCsrfToken();

  const buildForm = () => {
    const postLocation = `/studies/${studyId}/reports/ae_term_reconciliation/approve_terms`;

    const buildInputs = () => {
      return selectedForms.map((form) => {
        return (
          <input
            type="hidden"
            name="approved_forms[]"
            value={form}
            key={form}
          ></input>
        );
      });
    };

    return (
      <form
        action={postLocation}
        method="post"
        className="mb-2 d-flex justify-content-end"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken || ""}
        />
        {buildInputs()}
        <Button
          variant="primary"
          type="submit"
          disabled={selectedForms.length == 0}
          data-confirm="This cannot be undone, are you sure you would like to approve the selected forms"
        >
          Approve Selected Forms
        </Button>
      </form>
    );
  };

  return (
    <>
      {data.length > 0 && (
        <div className="mb-4">
          <CSVDownloadButton
            headers={[
              "Participant ID",
              "Start Date",
              "Details",
              "Raw Term",
              "Approved?",
            ]}
            data={csvData}
            filename={`${studyId}_ae_term_reconciliation_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results as CSV
          </CSVDownloadButton>
        </div>
      )}
      {filteredData.length == 0 ? (
        <div className="text-muted">There are no unapproved MedDRA forms.</div>
      ) : (
        <>
          {buildForm()}
          <ResultTable
            forms={filteredData}
            selectForm={selectForm}
            selectedForms={selectedForms}
            className="mt-4"
          ></ResultTable>
        </>
      )}
    </>
  );
};

export default AeTermReconciliationReportPage;
