import React from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";

const AdvancedSearchBox = ({
  buildInputName,
  updateFormValue,
  updateFormValues,
  advancedMeddraSearch,
  advancedSearchLocked,
  readonly,
  setResponseData,
  alternateSearchKeywordFieldData,
  lltTermFieldData,
  loading,
  advancedSearchVisible,
  advanced_meddra_allowed,
  useAdvancedMeddra,
  setUseAdvancedMeddra,
  invalidSearchWords,
  setInvalidSearchWords,
}) => {
  if (!advancedSearchVisible) {
    return null;
  }

  const inputName = buildInputName("alternate_search_keyword");

  const onChangeHandler = (e) => {
    updateFormValue("alternate_search_keyword", e.target.value);
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      advancedMeddraSearch();
    }
  };

  let formControlClasses = ["px-2", "py-1"];
  if ((advancedSearchLocked && !readonly) || loading) {
    formControlClasses.push("field-bg-readonly noClick");
  }

  const buildSearchButton = () => {
    if (readonly) {
      return null;
    }

    const unlockSearch = () => {
      setResponseData(null);
      // Ensure that we reset our form values when the search is unlocked

      // If we have the advanced meddra permission, unlocking the advanced search box clears the alternate search keyword
      // Otherwise it clears the raw term too
      const newFormValues = advanced_meddra_allowed
        ? [
            ["llt_term", null],
            ["llt_code", null],
            ["pt_term", null],
            ["pt_code", null],
            ["soc_term", null],
            ["soc_code", null],
            ["api_response", null],
            ["meddra_version", null],
          ]
        : [
            ["llt_term", null],
            ["llt_code", null],
            ["pt_term", null],
            ["pt_code", null],
            ["soc_term", null],
            ["soc_code", null],
            ["api_response", null],
            ["meddra_version", null],
          ];

      updateFormValues(newFormValues);
      setInvalidSearchWords(null);

      if (!advanced_meddra_allowed) {
        setUseAdvancedMeddra(false);
      }
    };

    if (advancedSearchLocked) {
      return (
        <div
          className="btn btn-primary"
          onClick={unlockSearch}
          id="meddra-unlock"
        >
          Unlock Search Term
        </div>
      );
    } else if (loading) {
      return (
        <div className="btn btn-primary disabled" disabled={true}>
          Loading <i className="fas fa-spinner-third fa-spin"></i>
        </div>
      );
    } else {
      let buttonClasses = ["btn", "btn-primary"];
      if (!alternateSearchKeywordFieldData.value) {
        buttonClasses.push("disabled");
      }

      return (
        <div
          className={buttonClasses.join(" ")}
          disabled={!alternateSearchKeywordFieldData.value}
          onClick={advancedMeddraSearch}
          id="meddra-search"
        >
          {lltTermFieldData.value ? "Search to Edit Coding" : "Search"}
        </div>
      );
    }
  };

  return (
    <>
      {!readonly && (
        <div className="mb-3 align-items-center row">
          <div className="col-sm-5"></div>
          <div className="col-sm-auto">
            <Form.Check
              id="useAdvancedSearch"
              checked={useAdvancedMeddra}
              onChange={() => {
                setUseAdvancedMeddra(!useAdvancedMeddra);
                setResponseData(null);
                updateFormValues([
                  ["llt_term", null],
                  ["llt_code", null],
                  ["pt_term", null],
                  ["pt_code", null],
                  ["soc_term", null],
                  ["soc_code", null],
                ]);
              }}
              label="Use Keyword Search?"
            />
          </div>
        </div>
      )}

      {useAdvancedMeddra && (
        <>
          <div className="mb-3 align-items-center row">
            <Form.Label className="fw-bold lh-sm form-label col-form-label col-sm-5">
              Advanced Keyword Search
            </Form.Label>
            <div className="col-sm-auto">
              <InputGroup>
                {advancedSearchLocked && !readonly && (
                  <InputGroup.Text>
                    <i className="fas fa-lock"></i>
                  </InputGroup.Text>
                )}
                <Form.Control
                  plaintext={readonly}
                  readOnly={advancedSearchLocked || loading}
                  type="text"
                  value={alternateSearchKeywordFieldData.value || ""}
                  name={inputName}
                  onChange={onChangeHandler}
                  onKeyDown={keyDownHandler}
                  className={formControlClasses.join(" ")}
                ></Form.Control>
                {buildSearchButton()}
              </InputGroup>
            </div>
          </div>
          {invalidSearchWords && invalidSearchWords.length > 0 && (
            <Alert
              variant="danger"
              className="mb-3 col-7 mx-auto"
              id="invalid-search-words"
            >
              The following search words returned no matches and have been
              ignored:
              <ul>
                {invalidSearchWords.map((invalidLLt, index) => {
                  return <li key={index}> {invalidLLt} </li>;
                })}
              </ul>
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default AdvancedSearchBox;
