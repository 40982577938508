import React from "react";

import { Table } from "react-bootstrap";

const ResultTable = ({ forms, currentMeddraVersion }) => {
  const renderedRows = forms.map((form, index) => {
    return (
      <tr
        key={form.participant_id}
        id={form.participant_id.replace(/\//g, "-")}
      >
        <td>
          <a href={form.individual_link}>{form.participant_id}</a>
        </td>
        <td>
          {form.start_date || <span className="text-muted">[blank]</span>}
        </td>
        <td>
          <a href={form.form_link}>{form.raw_term}</a>
        </td>
        <td>{form.alternate_search_keywords || "[none]"}</td>
        <td>{form.llt_term}</td>
        <td>{form.pt_term}</td>
        <td>{form.meddra_version}</td>
        <td>{form.meddra_version != currentMeddraVersion ? "Yes" : "No"}</td>
        <td>{form.meddra_update_state}</td>
      </tr>
    );
  });

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th>Participant ID</th>
          <th>Start Date</th>
          <th>Raw Term</th>
          <th>Alternate Search Keywords</th>
          <th>Lower Level Term</th>
          <th>Preferred Term</th>
          <th>Forms Current MedDRA Version</th>
          <th>Mismatch?</th>
          <th>MedDRA Update State</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
