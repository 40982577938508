import React from "react";

import { Card, Row, Col } from "react-bootstrap";
import { SelectField } from "components/helpers/fields";
import { CSVDownloadButton } from "components/helpers";

const ReportFilter = ({
  studyId,
  sites,
  selectedSites,
  updateSiteSelect,
  selectedMismatchStatus,
  updateMismatchStatus,
  csvData,
}) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="mb-0">Filter</Card.Title>
      </Card.Header>
      <Card.Body className="pb-2">
        <Row>
          <Col md={6}>
            <SelectField
              fieldName="selectedSites"
              label="Site"
              placeholder="Select Sites..."
              options={sites}
              value={selectedSites}
              onFieldChange={updateSiteSelect}
              isMulti={true}
              isClearable={true}
              required={true}
              errors={[]}
              selectAllHandler={() => {
                updateSiteSelect(
                  "selectedElements",
                  sites.map((element) => element.value)
                );
              }}
            ></SelectField>
          </Col>
          <Col md={6}>
            <SelectField
              fieldName="selectedMismatchState"
              label="Mismatched?"
              placeholder="Select Mismatch Status..."
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              value={selectedMismatchStatus}
              onFieldChange={updateMismatchStatus}
              isClearable={true}
              required={true}
              errors={[]}
            ></SelectField>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex gap-2 justify-content-end">
          <CSVDownloadButton
            headers={[
              "Participant ID",
              "Start Date",
              "Raw Term",
              "Alternate Search Keywords",
              "Lower Level Term",
              "Preferred Term",
              "Forms Current MedDRA Version",
              "Mismatch?",
              "MedDRA Update State",
            ]}
            data={csvData}
            filename={`${studyId}_meddra_version_update_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results as CSV
          </CSVDownloadButton>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ReportFilter;
