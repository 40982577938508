import React from "react";
import { useState } from "react";

import ResultTable from "../components/uncoded_meddra_terms_report/resultsTable";
import { CSVDownloadButton } from "components/helpers";

const UncodedMeddraTermsReportPage = ({ studyId, data }) => {
  const buildCSVData = () => {
    return data.map((form) => {
      return [
        form.participant_id,
        form.start_date,
        form.raw_term,
        form.alternate_search_keywords ? "Yes" : "No",
      ];
    });
  };

  const csvData = buildCSVData();

  return (
    <>
      {data.length == 0 ? (
        <div className="text-muted">There are no uncoded MedDRA forms.</div>
      ) : (
        <>
          <div className="mb-4">
            <CSVDownloadButton
              headers={[
                "Participant ID",
                "Start Date",
                "Raw Term",
                "Keyword Coded?",
              ]}
              data={csvData}
              filename={`${studyId}_uncoded_meddra_terms_report`}
              addTimeStamp={true}
              active={csvData.length > 0}
            >
              Download results as CSV
            </CSVDownloadButton>
          </div>
          <ResultTable forms={data}></ResultTable>
        </>
      )}
    </>
  );
};

export default UncodedMeddraTermsReportPage;
