import React from "react";

import { Card, Row, Col } from "react-bootstrap";
import { CheckboxField } from "components/helpers/fields";
import { CSVDownloadButton } from "components/helpers";

const ReportFilter = ({
  studyId,
  selectedExactMatch,
  updateSelectedExactMatch,
  csvData,
}) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="mb-0">Filter</Card.Title>
      </Card.Header>
      <Card.Body className="pb-2">
        <Row>
          <Col md={6}>
            <CheckboxField
              fieldName="exactMatch"
              label="Show Only Exact Matches"
              value={selectedExactMatch}
              onFieldChange={updateSelectedExactMatch}
              errors={[]}
            ></CheckboxField>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex gap-2 justify-content-end">
          <CSVDownloadButton
            headers={[
              "Raw Term",
              "LLT",
              "PT",
              "SOC",
              "Status",
              "Approved/Inappropriate By",
              "Approved/Inappropriate At",
            ]}
            data={csvData}
            filename={`${studyId}_meddra_approvals_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results for {csvData.length} form
            {csvData.length == 1 ? null : "s"}
          </CSVDownloadButton>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ReportFilter;
